<script setup lang="ts">
  import { OrderManagerAsUser } from '~/api/data-contracts';

  const props = defineProps<{
    firstName?: OrderManagerAsUser['firstName'];
    lastName?: OrderManagerAsUser['lastName'];
    id: OrderManagerAsUser['id'];
  }>();

  const initials = computed(() => {
    return [props.firstName, props.lastName]
      .map((v) => v && v[0])
      .filter(Boolean)
      .join('');
  });

  const fullName = useFullName(props.firstName, props.lastName);

  const color = computed(() => {
    let hash = 0;
    (props.id || '').split('').forEach((char) => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, '0');
    }
    return colour;
  });
</script>

<template>
  <abbr
    class="relative block aspect-square overflow-hidden rounded-full border border-[var(--color)] bg-grayscale-100 text-grayscale-300 dark:bg-grayscale-700"
    :style="`--color: ${color}`"
    :title="fullName"
  >
    <svg viewBox="0 0 100 100">
      <rect
        x="0"
        y="0"
        width="100"
        height="100"
        fill="transparent"
        style="--svg-opacity: 0.2"
      />
      <text
        x="50%"
        y="50%"
        dominant-baseline="central"
        text-anchor="middle"
        font-size="40"
        style="--svg-opacity: 1"
      >
        {{ initials }}
      </text>
    </svg>
  </abbr>
</template>

<style scoped>
  svg rect,
  svg text {
    fill: var(--color);
    opacity: var(--svg-opacity);
  }
</style>
