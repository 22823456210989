import { User } from '~/api/data-contracts';

export const useFullName = (
  firstName: User['firstName'],
  lastName: User['lastName'],
) => {
  const fullname = computed(() => {
    return [firstName, lastName].filter(Boolean).join(' ');
  });

  return fullname;
};
